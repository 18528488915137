import { apiSecond } from '@/boot/axios'

export default {
	state: {
		reservoirs: [],
		regions: [],
	},
	mutations: {
		SET_RESERVOIRS(state, payload) {
			state.reservoirs = payload
		},
		SET_REGIONS(state, payload) {
			state.regions = payload
		},
	},
	actions: {
		async _fetchReservoirs({ commit }) {
			apiSecond
				.get('/v2/handbook-reservoirs')
				.then((response) => {
					commit('SET_RESERVOIRS', response.data.data || [])
				})
				.catch((error) => {
					throw error
				})
		},
		async _fetchRegions({ commit }) {
			apiSecond
				.get('/v2/handbook-regions')
				.then((response) => {
					commit('SET_REGIONS', response.data.data || [])
				})
				.catch((error) => {
					throw error
				})
		},
	},
}
